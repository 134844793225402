// Copyright © 2022 Move Closer

import VueI18n, { Locale } from 'vue-i18n'

import { HtmlLang } from '@contract/i18n'
import { loadLocaleMessages } from '@support/i18n'
// TODO: Locales
// import { pluralizationRulesEN, pluralizationRulesPL } from '@/shared/modules'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const FALLBACK_LOCALE: Locale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl'

/**
 * @author Maciej Perzakowski <maciej.perzankowski@movecloser.pl>
 */
export const INITIAL_LOCALE: Locale = process.env.VUE_APP_I18N_INITIAL_LOCALE || 'pl'

/**
 * Registry that binds the `VueI18n.Locale` with the applicable `HtmlLang`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const localeHtmlLangRegistry: Record<Locale, HtmlLang> = {
  en: HtmlLang.En,
  pl: HtmlLang.Pl
}

/**
 * Create i18n instance.
 *
 * @param locale
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export function createI18n (locale: string = INITIAL_LOCALE): VueI18n {
  return new VueI18n({
    fallbackLocale: FALLBACK_LOCALE,
    locale,
    messages: loadLocaleMessages(),
    pluralizationRules: {
      pl: function (choice: number, choicesLength: number): number {
        if (choice >= 0 && choice < 5) {
          return choice
        }

        return 5
      }
    }
  })
}
