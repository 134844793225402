
import { VueConstructor } from 'vue'

import Basic from './variants/Basic.vue'
import Rich from './variants/Rich.vue'
import { RecommendationAction } from '../../../products/contracts/repositories'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ADDED_TO_CART_MODAL_COMPONENT_KEY = 'AddedToCartModalComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum AddedToCartModalLayout {
  Basic = 'basic',
  Rich = 'rich'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AddedToCartModalConfig {
  action: string
  layout: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP: AddedToCartModalConfig = {
  action: RecommendationAction.AddToCart,
  layout: AddedToCartModalLayout.Basic
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const addedToCartModalComponentRegistry: Record<string, VueConstructor> = {
  [AddedToCartModalLayout.Basic]: Basic,
  [AddedToCartModalLayout.Rich]: Rich
}
