














import { Component, Prop, Vue } from 'vue-property-decorator'

import { Variant as ProductVariant } from '../../../../../contexts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PriceNameBar>({
  name: 'PriceNameBar'
})
export class PriceNameBar extends Vue {
  @Prop({ type: Object, required: true })
  public variant!: ProductVariant<string>

  @Prop({ type: Boolean, required: true })
  public shouldRender!: boolean

  @Prop({ type: Boolean, required: true })
  public showPriceAtMobileProductHeader!: boolean
}

export default PriceNameBar
