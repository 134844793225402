// Copyright © 2022 Move Closer

import { Injectable, IObserver } from '@movecloser/front-core'

import { AnalyticsConfig, ContactFormConfig, PageLoadedEvent } from '../contracts'
import { UserModel } from '../../auth/shared'
import {
  AbstractEvent,
  AddPaymentInfoConfig,
  AddPaymentInfoEvent,
  AddShippingInfoConfig,
  AddShippingInfoEvent,
  AddToCartConfig,
  AddToCartEvent,
  BeginCheckoutConfig,
  BeginCheckoutEvent,
  LoginEvent,
  PageViewEvent,
  PurchaseConfig,
  PurchaseEvent,
  RemoveFromCartConfig,
  RemoveFromCartEvent,
  SignUpEvent,
  SubscribeConfig,
  SubscribeEvent,
  Trackify,
  TRACKIFY_DEBUG,
  TRACKIFY_GTM,
  TRACKIFY_SYNERISE,
  TRACKIFY_USERCOM,
  UnsubscribeEvent,
  UserDataEvent,
  ViewCartConfig,
  ViewCartEvent,
  ViewItemConfig,
  ViewItemEvent, ViewItemListConfig, ViewItemListEvent
} from '@kluseg/trackify/dist/main'
import { ISiteService } from '../../../contexts'
import { ContactEvent } from '../events/contact.event'

/**
 * Class to observe common UI changes and send analytics to drivers
 *
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl> <update>
 */
@Injectable()
export class GAObserver implements IObserver {
  protected isInit: boolean = false
  protected trackify: Trackify | undefined

  private staticCurrency: 'PLN' | 'USD' = 'PLN'

  constructor (protected analyticsConfig: AnalyticsConfig, protected siteService: ISiteService) {
    try {
      this.trackify = new Trackify()
      if (this.analyticsConfig.TRACKIFY_DEBUG) {
        this.trackify.useDriver(TRACKIFY_DEBUG)
      }
      if (this.analyticsConfig.TRACKIFY_GTM) {
        this.trackify.useDriver(TRACKIFY_GTM, { layerId: 'dataLayer' })
      }
      if (this.analyticsConfig.TRACKIFY_SYNERISE) {
        this.trackify.useDriver(TRACKIFY_SYNERISE)
      }
      if (this.analyticsConfig.TRACKIFY_USERCOM) {
        this.trackify.useDriver(TRACKIFY_USERCOM)
      }
      this.trackify.loadDrivers().then(() => {
        this.isInit = true
      })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.log(error.message)
    }
  }

  /**
   * List of observables.
   */
  public get observableEvents () {
    return {
      'app:cart.add': 'onAddToCart',
      'app:cart.remove': 'onRemoveFromCart',
      'app:cart.view': 'onViewCart',
      'app:checkout.begin': 'onBeginCheckout',
      'app:checkout.addPaymentInfo': 'onAddPaymentInfo',
      'app:checkout.addShippingInfo': 'onAddShippingInfo',
      'app:checkout.purchase': 'onPurchase',
      'app:authorization.login': 'onLogin',
      'app:authorization.sign_up': 'onSignUp',
      'app:page.loaded': 'onPageView',
      'app:product.view': 'onViewItem',
      'app:product_list.view': 'onViewItemList',
      'app:user.change_data': 'onUserDataChange',
      'app:contactForm.submit': 'onContactForm',
      'app:newsletter.subscribe': 'onSubscribeNewsletter',
      'app:newsletter.unsubscribe': 'onUnsubscribeNewsletter'
    }
  }

  /**
   * Function to track page changes
   * @private
   */
  public onPageView (event: PageLoadedEvent): void {
    this.observe(new PageViewEvent({
      language: event.locale,
      pagePath: event.path,
      pageTitle: event.title,
      currency: this.staticCurrency,
      turnOffPageViewForSPA: event.turnOffPageViewForSPA
    }))
  }

  public onAddToCart (event: AddToCartConfig): void {
    this.observe(new AddToCartEvent({ ...event, currency: this.staticCurrency }))
  }

  public onAddPaymentInfo (event: AddPaymentInfoConfig): void {
    this.observe(new AddPaymentInfoEvent({
      ...event, currency: this.staticCurrency
    }))
  }

  public onAddShippingInfo (event: AddShippingInfoConfig): void {
    this.observe(new AddShippingInfoEvent({
      ...event,
      currency: this.staticCurrency
    }))
  }

  public onBeginCheckout (event: BeginCheckoutConfig): void {
    this.observe(new BeginCheckoutEvent({
      ...event,
      currency: this.staticCurrency
    }))
  }

  public onLogin (event: UserModel): void {
    this.observe(new LoginEvent({
      method: 'web',
      firstname: event.firstName,
      lastname: event.lastName,
      email: event.email,
      id: event.email
    }))
  }

  public onSignUp (event: UserModel): void {
    this.observe(new SignUpEvent({
      method: 'web',
      firstname: event.firstName,
      lastname: event.lastName,
      email: event.email,
      id: event.email
    }))
  }

  public onPurchase (event: PurchaseConfig): void {
    this.observe(new PurchaseEvent({ ...event, currency: this.staticCurrency }))
  }

  //
  public onRemoveFromCart (event: RemoveFromCartConfig): void {
    this.observe(new RemoveFromCartEvent({
      ...event,
      currency: this.staticCurrency
    }))
  }

  public onUserDataChange (event: UserModel) {
    this.observe(new UserDataEvent({
      id: event.id as string,
      email: event.email,
      firstname: event.firstName,
      lastname: event.lastName,
      dateOfBirth: event.dateOfBirth,
      phone: event.phone
      // role: event.role,
    }))
  }

  public onViewCart (event: ViewCartConfig): void {
    this.observe(new ViewCartEvent({ ...event, currency: this.staticCurrency }))
  }

  public onViewItem (event: ViewItemConfig): void {
    // const product = event.items[0]
    // Hack to delay event. It is done to emit event 'page_view' first.
    setTimeout(() => {
      this.observe(new ViewItemEvent({ ...event, currency: this.staticCurrency }))
    }, 0)
  }

  public onSubscribeNewsletter (event: SubscribeConfig): void {
    const storeCode = this.siteService.getActiveSite().getProperty('storeCode', '')
    const locale = this.siteService.getActiveSiteLocale()

    this.observe(new SubscribeEvent({
      email: event.email,
      list: event.list,
      language: locale as string || '',
      allow_marketing: event.allow_marketing,
      allow_policy: event.allow_policy,
      storeCode: storeCode as string || ''
    }))
  }

  private static capitalizeFirstLetter (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public onContactForm (event: ContactFormConfig): void {
    const storeCode = this.siteService.getActiveSite().getProperty('storeCode', '')
    const locale = this.siteService.getActiveSiteLocale()

    const emailUsername = event.email.split('@')[0]
    const fallbackFirstName = GAObserver.capitalizeFirstLetter(emailUsername.split('.')[0] ?? '')
    const fallbackLastName = GAObserver.capitalizeFirstLetter(emailUsername.split('.')[1] ?? '')
    this.observe(new ContactEvent({
      email: event.email,
      name: event.name ?? `${fallbackFirstName} ${fallbackLastName}`,
      firstname: event.firstname ?? fallbackFirstName,
      lastname: event.lastname ?? fallbackLastName,
      subject: event.subject,
      message: event.message,
      emailOffers: event.emailOffers ? 'enabled' : 'disabled',
      smsOffers: event.smsOffers ? 'enabled' : 'disabled',
      acceptPrivacy: event.acceptPrivacy ? 'true' : 'false',
      language: locale as string || '',
      storeCode: storeCode as string || ''
    }))
  }

  public onUnsubscribeNewsletter (event: SubscribeConfig): void {
    this.observe(new UnsubscribeEvent({
      email: event.email
    }))
  }

  public onViewItemList (event: ViewItemListConfig): void {
    this.observe(new ViewItemListEvent(event))
  }

  protected observe (event: AbstractEvent): void {
    if (!this.isInit || !this.trackify) return

    this.trackify.track(event)
  }
}

export const GAEventObserverType = Symbol.for('GAEventObserver')
