// Copyright © 2021 Move Closer

import { IModuleConstructor } from '@movecloser/front-core'

import { frontAppModules } from '@core'

import { ContentModule } from '@module/content'
import { OverridesModule } from '@module/overrides'
import { RootModule } from '@module/root'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const AppModules: IModuleConstructor[] = [
  ...frontAppModules,
  RootModule,
  // Overrides services/queries/repos/etc if necessary, so it must be registered in the end.
  OverridesModule,
  // This module has to be last one due to its `catch-all` route.
  ContentModule
]
