// Copyright © 2021 Move Closer

import { IToastOptions } from '../../../backoffice/shared/services/toaster'

/**
 * This config allows to specify Toastr service options.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const toasts: IToastOptions = {
  dismissible: false,
  duration: 2000,
  ripple: false,
  types: [],
  position: {
    x: 'right',
    y: 'bottom'
  }
}
