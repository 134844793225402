// Copyright © 2021 Move Closer

import { Locale, LocaleMessages } from 'vue-i18n'
import merge from 'lodash/merge'

import { frontAppPL as coreFrontAppPL } from '@/shared/core/src/locales/pl'
import { frontAppPL } from '@/locales/pl'

// import { frontAppEN as coreFrontAppEN } from '@/shared/core/src/locales/en'
import { frontAppEN } from '@/locales/en'

import { FALLBACK_LOCALE, localeHtmlLangRegistry } from '@/bootstrap/i18n'
import { HtmlLang } from '@contract/i18n'

/**
 * Resolves the applicable `HtmlLang` for the passed-in `Locale`.
 *
 * @param locale - Current locale (language) of the app.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getHtmlLangAttrValue = (locale: Locale): HtmlLang => {
  if (
    typeof locale !== 'string' ||
    !Object.prototype.hasOwnProperty.call(localeHtmlLangRegistry, locale) ||
    typeof localeHtmlLangRegistry[locale] !== 'string'
  ) {
    return getHtmlLangAttrValue(FALLBACK_LOCALE)
  }

  return localeHtmlLangRegistry[locale]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
export const loadLocaleMessages = (): LocaleMessages => {
  const localeMessages: LocaleMessages = {}

  // fixme: is this file import neccessary?
  // const uiCoreLocaleMessages = require.context(
  //   '@movecloser/ui-core/lib/libraries/bootstrap/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i
  // )
  // pushMessages(localeMessages, uiCoreLocaleMessages)

  // merge(localeMessages, { pl: coreFrontAppPL }, { pl: frontAppPL })
  merge(localeMessages, { en: {} }, { en: frontAppEN })

  return localeMessages
}

/**
 * Pushes the passed-in source messages into the given target object.
 *
 * @param target - The object that the messages should be pushed into.
 * @param source - The object that the messages should be extracted from.
 * @param [useMerge=false] - Determines whether the Lodash's `merge()` function should be used.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const pushMessages = (
  target: LocaleMessages,
  source: __WebpackModuleApi.RequireContext,
  useMerge: boolean = false
): void => {
  source.keys().forEach((filename: string) => {
    const matches = filename.match(/([A-Za-z0-9-_]+)\./i)

    if (matches && matches.length > 1) {
      const locale: string = matches[1]
      if (typeof target[locale] === 'undefined') {
        target[locale] = {}
      }
      useMerge ? merge(target[locale], source(filename)) : target[locale] = source(filename)
    }
  })
}
