import {
  ICollection, IConnector,
  Injectable, IResponse, mapModel, MappingConfig, Repository, ResourceActionFailed
} from '@movecloser/front-core'

import { IGraphQL } from '../../../contexts'
import { OrderData, SingleOrderData } from '../../../contexts/orders/contracts/orders'
import { resolveFromStatus } from '../../../support'

import { IOrdersRepository } from '../contracts/repository'
import { orderAdapterMap } from '../models/order.adapter'
import { Order } from '../models/order'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class OrdersRepository extends Repository<OrderData, Order> implements IOrdersRepository {
  protected map: MappingConfig = orderAdapterMap
  protected useAdapter = true
  protected graphQlConnector: IGraphQL

  constructor (connector: IConnector, graphQlConnector: IGraphQL) {
    super(() => connector)
    this.graphQlConnector = graphQlConnector
  }

  /**
   * @inheritDoc
   */
  public async loadOrders (
    currentPage: number,
    pageSize: number
  ): Promise<{ orders: ICollection<OrderData>; totalCount: number }> {
    const response: IResponse = await this.graphQlConnector.call('ordersQuery', { currentPage, pageSize })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return {
      orders: this.composeCollection(response.data.customer.orders.items, Order, []),
      totalCount: response.data.customer.orders.total_count
    }
  }

  /**
   * @inheritDoc
   */
  public async loadSingleOrder (
    order: string
  ): Promise<SingleOrderData> {
    const response: IResponse = await this.graphQlConnector.call('singleOrder', { order })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    const model: OrderData = mapModel(response.data.customer.orders.items[0], this.map, false)

    /**
     * Compose order object
     */
    return {
      addresses: {
        billing: model.billingAddress,
        shipping: model.shippingAddress
      },
      id: model.id,
      costs: {
        products: model.productsCost,
        shipping: model.shippingCost,
        total: model.totalCost
      },
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
      discounts: model.total.discounts,
      paymentMethod: model.paymentMethod,
      products: model.products,
      shippingMethod: model.carrier,
      status: model.statusCode
    }
  }

  /**
   * @inheritDoc
   */
  public async checkOrderPaymentStatus (
    orderNumber: string
  ): Promise<string> {
    const response: IResponse = await this.graphQlConnector.call('checkOrderPaymentStatusQuery', { orderNumber })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return response.data.orderPaymentStatus
  }
}
