










import { Component, Mixins, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { DisplaySearchResult } from '../../../../contexts'

import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'

import { SearchResultProductDesktop } from './partials/SearchResultProductDesktop.vue'
import { SearchResultProductMobile } from './partials/SearchResultProductMobile.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultProductItem>({
  name: 'SearchResultProductItem',
  components: {
    SearchResultProductDesktop,
    SearchResultProductMobile
  }
})
export class SearchResultProductItem extends Mixins(ProductCartMixin) implements DisplaySearchResult {
  @Prop({ type: Object, required: false })
  public element!: AnyObject
}

export default SearchResultProductItem
