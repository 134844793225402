








import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProductData, Variant } from '../../../../../contexts'

import {
  Action as CartItemActions,
  CartItemProps
} from '../../../../checkout/molecules/CartItem/CartItem.contracts'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import { translateToCartItem } from '../../../../checkout/molecules/CartItem/CartItem.helpers'
import { CartItemAction } from '../../../../checkout/molecules/CartItem/CartItem.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultProductDesktop>({
  name: 'SearchResultProductDesktop',
  components: { CartItem }
})
export class SearchResultProductDesktop extends Vue {
  @Prop({ type: Function, required: false })
  public onAddToCart?: (product: Variant<string>) => Promise<void>

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  /**
   * Determines actions of cart item.
   */
  public get actions (): CartItemActions[] {
    if (typeof this.onAddToCart !== 'function') {
      return []
    }

    return [
      {
        label:
          this.$t('front.shared.searchResults.actions.addToCart').toString(),
        key: CartItemAction.AddToCart,
        icon: {
          color: '#5F5F5F',
          height: '1em',
          name: 'ShoppingCartIcon',
          width: '1em'
        },
        onClick: () => typeof this.onAddToCart === 'undefined' ? Promise.resolve()
          : this.onAddToCart(this.variants[0])
      }
    ]
  }

  public get toCartItemProps (): CartItemProps {
    return translateToCartItem(this.product, ['noQuantity'])
  }

  public get variants (): Variant<string>[] {
    return Object.values(this.product.variants)
  }

  public onIsLoadingHandler (value: boolean): void {
    this.$emit('isLoading', value)
  }
}
export default SearchResultProductDesktop
