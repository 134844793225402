import { CurrencyConfig } from '@/shared/core/src/support/filters'

export const extensions = {
  currency: {
    cropZeros: true,
    defaultCode: 'AED',
    currencyMap: {
      PLN: 'zł'
    },
    separator: '.'
  } as CurrencyConfig
}
