// Copyright © 2021 Move Closer

import { AppModule, Container, IConfiguration, Module } from '@movecloser/front-core'

import { GraphQlConnectorType, IGraphQL } from '@core'

import { checkoutQueries } from './queries'

/**
 *
 * Overrides repos/services/resources/etc if necessary.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@AppModule({
  name: 'overrides',
  boot (container: Container, config: IConfiguration) {
    const graph = container.get<IGraphQL>(GraphQlConnectorType)
    graph.useQueries(checkoutQueries(config))
  }
})
export class OverridesModule extends Module {}
