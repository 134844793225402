// Copyright © 2022 Move Closer

import 'reflect-metadata'
import 'wicg-inert'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NoSSR from 'vue-no-ssr'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import { WindowService } from '@movecloser/front-core'

import { CustomDSL, FrontCore, registerExtensions } from '@core'
import { BootedApp, createApp } from '@/bootstrap/app'
import { Context } from '@contract/context'
import { initSentry } from '@support/sentry'
import { isCallingForFile } from '@support/entry-point'

import './scss/index.scss'
import App from './App.vue'
import { buildDSLRegistry } from '@/shared/icons'

Vue.config.productionTip = false

Vue.use(CustomDSL, {
  icons: buildDSLRegistry(),
  prefix: 'Ui'
})
Vue.use(FrontCore)

Vue.use(VueI18n)
Vue.use(VueMeta)

Vue.component('no-ssr', NoSSR)

export default (context: Context): Promise<Vue> => {
  if (isCallingForFile(context.url)) {
    const message: string = 'File not found'
    if (context.isClient) {
      throw new Error(message)
    }

    context.res?.status(404).send(message)
    return Promise.reject(
      new Error(message)
    )
  }

  return createApp(context).then((bootedApp: BootedApp) => {
    const {
      configuration,
      container,
      i18n,
      router,
      store
    } = bootedApp

    registerExtensions(Vue, container, configuration.byKey('extensions', false) ?? undefined)

    if (WindowService.isDefined && process.env.VUE_APP_ENV !== 'local') {
      initSentry()
    }

    return new Vue({
      configuration,
      container,
      i18n,
      render: h => h(App),
      router,
      store
    })
  }).catch(error => {
    throw new Error(error.message)
  })
}
