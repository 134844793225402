import { AppModule, AuthServiceType, Container, IConfiguration, Interfaces, Module } from '@movecloser/front-core'

import { GraphQlConnectorType, IGraphQL } from '../../contexts'
import { COMPONENTS_STRUCTURE_CONFIG, logger } from '../../support'

import { IProfileTabsService, ProfileTabsServiceType } from '../profile/services/profile-tabs'
import { pickTabItemsFactory } from '../shared/support/tabs.factory'

import { AuthGraphQLMiddlewareType } from '../auth/services'

import { IWishList, WishListService, WishListServiceType } from './services/wishlist'

import { wishListQueries } from './config/queries'
import { routesFactory as routes } from './routes'
import { storeFactory as state } from './store'
import { ComponentsStructureConfig } from '../../support/mixins'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@AppModule({
  name: 'wishlist',
  providers: () => (bind: Interfaces.Bind) => {
    bind<IWishList>(WishListServiceType).toDynamicValue((context) => {
      return new WishListService(context.container.get(GraphQlConnectorType), context.container.get(AuthServiceType))
    }).inSingletonScope()
  },
  boot (container: Container, config: IConfiguration) {
    const configuration = config.byKey<ComponentsStructureConfig>(COMPONENTS_STRUCTURE_CONFIG)

    const graph = container.get<IGraphQL>(GraphQlConnectorType)
    graph.useMiddlewares(container.get(AuthGraphQLMiddlewareType))
    graph.useQueries(wishListQueries)

    const profileTabsService = container.get<IProfileTabsService | undefined>(ProfileTabsServiceType)
    if (profileTabsService) {
      profileTabsService.addTab(pickTabItemsFactory(configuration, 'wishlist'))
    } else {
      logger('[OrdersModule]: There\'s no ProfileTabsService available', 'debug')
    }
  },
  routes,
  state
})

export class WishListModule extends Module {}
