import { gql } from 'graphql-tag'

import { QueryRegistryFactory } from '@core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const checkoutQueries: QueryRegistryFactory = (config) => {
  return ({
    placeOrder: {
      type: 'mutation',
      query: gql`
        mutation placeOrder($cartId: String!) {
          placeOrder(
            input: {
                cart_id: $cartId
            }
          ) {
              order {
                order_number
                payment_method
                instructions
                ngeniusPaymentUrl
              }
            }
          }`,
      meta: {
        noAuth: false
      }
    }
  })
}
