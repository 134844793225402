


















/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { ImageData } from '../../../../../contexts'

import { toImageProps } from '../../../../shared/support'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'

@Component({ name: 'Default' })
export class Default extends Vue {
  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  public getStyle (identifier: string | ImageData): AnyObject {
    if (typeof identifier === 'string') {
      return { backgroundColor: identifier }
    }

    return { backgroundImage: `url(${toImageProps(identifier).src})` }
  }

  public get variantId () {
    return Math.floor(Math.random() * 1000)
  }
}

export default Default
