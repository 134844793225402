// Copyright © 2021 Move Closer

import { AppConfig } from '@movecloser/front-core'

import { COMPONENTS_STRUCTURE_CONFIG, MagentoVersion, PRIVACY_CONFIG } from '@core'

import { AppModules as modules } from './modules'
import { appQueries as queries } from './queries'
import { http } from './http'
import { configuration } from './components'
import { middlewares } from './middlewares'
import { modalsRegistry as modals } from './modal'
import { patchesRegistry as patches } from './patches'
import { privacy } from './privacy'
import { resources } from './resources'
import { ROUTER_DRIVER as router } from './router'
import { services } from './services'
import { analytics } from './analytics'
import { STORE_DRIVER as store } from './store'
import { extensions } from './extensions'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const config: AppConfig = {
  http,
  [COMPONENTS_STRUCTURE_CONFIG]: configuration,
  magentoVersion: MagentoVersion.Default,
  middlewares,
  modals,
  modules,
  nonProductionEnvironments: ['local'],
  queries,
  patches,
  [PRIVACY_CONFIG]: privacy,
  resources,
  router,
  services,
  store,
  analytics,
  // Has to be defined, even if it's empty, or an error will be thrown.
  modalConfig: {},
  extensions
}
