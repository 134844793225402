// Copyright © 202w Move Closer

export const configuration = {
  // e.g.
  // MegaMenuComponent: {
  //   bannerPosition: 'left'
  // },
  structure: {
    AddedToCartModalComponent: {
      action: 'complementary'
    },
    AccountView: {
      redirectFromAccountPage: 'orders.me.orders'
    },
    AddressFormFieldsetComponent: {
      isCountrySelectEnable: true,
      isPostalCodeRequired: false,
      isShipmentLabelActive: false
    },
    Cart: {
      cartSize: 'small',
      summaryItemWrappers: [],
      couponButtonTheme: '',
      couponButtonVariant: ''
    },
    CartItemComponent: {
      showAdditionalOptionsMobile: false
    },
    CartView: {
      cartItemSize: 'small',
      showEnhancedCouponMobile: false
    },
    CartSummaryComponent: {
      subtotalWrappers: [],
      taxWrappers: [],
      showFreeDeliveryProgress: false
    },
    CustomerDetailsStep: {
      buttonGoNextTheme: 'primary'
    },
    HeroModule: {
      linkDecorator: '--arrow',
      linkTheme: ''
    },
    LoginFormComponent: {
      form: {
        hasLabel: false
      }
    },
    MegaMenuComponent: {
      hasColumnAdditionalHeaderOnBottom: true,
      headerLinkSize: 'small'
    },
    NavbarModule: {
      shouldHideMobileNav: true,
      showLanguageSwitcher: false
    },
    NewsletterModule: {
      buttonIcon: 'ArrowLinkRightIcon',
      buttonTheme: 'link',
      alertButtonTheme: 'primary',
      isSubscriptionByUser: false
    },
    // PaymentStep: {
    //   drivers: ['cashondelivery', 'banktransfer', 'ngeniusonline'],
    //   icons: {
    //     cashondelivery: require('/assets/images/przy-odbiorze.svg'),
    //     banktransfer: require('/assets/images/przelew.png'),
    //     ngeniusonline: require('/assets/images/ngeniusonline.png')
    //   }
    // },
    ProductCard: {
      buttonTheme: 'primary',
      buttonVariant: '',
      badgeVariant: 'outline',
      badgeTheme: '',
      themeOfNewBadge: ''
    },
    ProductHeaderComponent: {
      favouriteAsIcon: true,
      isVariantNameEnabled: true,
      shouldDisplayReviewWithDescription: false,
      showPriceAtMobileProductHeader: false
    },
    ProductReviewComponent: {
      shouldShowDescription: false
    },
    PrivacyModal: {
      defaultChecked: false,
      hasPrimaryBtn: true
    },
    ProfileWrapperComponent: {
      navListContainerClasses: ['container-narrow'],
      containerClass: ['container'],
      containerListClass: ['container-narrow']
    },
    SearchInputComponent: {
      DEFAULT_SEARCH_INPUT_PLACEHOLDER: 'Searched phrase'
    },
    ShippingStepComponent: {
      // drivers: ['SMD', 'OND', 'CDS', 'ONP'],
      drivers: [],
      buttonGoNextTheme: 'primary',
      useMagentoLabels: true
    },
    SignupFormComponent: {
      socials: {}
    },
    TextModuleForm: {
      allowedColors: ['primary', 'grey']
    },
    OrderListItem: {
      variant: '',
      theme: 'primary'
    },
    OrdersListItemComponent: {
      layout: 'default',
      returnOrderBtnVariant: 'full'
    },
    WishlistView: {
      inProfile: true
    },
    SearchResultsComponent: {
      searchFromChars: 1
    }
  },
  options: {},
  modules: {}
}
